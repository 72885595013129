import React, { useState, useEffect } from 'react';




// You need to import the CSS only once
import './assets/css/index.css';

const Title = (props) => {
  return (
    <header id="title-bar">
      <h1 dangerouslySetInnerHTML={{ __html: props.h1 }} /> 
      <h2 class="content">{props.content}</h2>
    </header>
  );
};

export default Title;
