import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, Modal, Tooltip, Divider } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faPlus, faBorderInner } from '@fortawesome/pro-light-svg-icons'

import { BorderOuterOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

// You need to import the CSS only once
import './assets/css/index.css';

const Details = (props) => {




  const items = [
    {
      label: 'Navigation One',
      key: 'mail',
      icon:  <BorderOuterOutlined />,
    },
    {
      label: 'Navigation Two',
      key: 'app',
      icon:  <BorderOuterOutlined />,
    },    
  ];

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    openInside(e.key)
  };

  const [current, setCurrent] = useState('mail');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openInside = (room) => {
    console.log(props);
    //handleCancel();
    props.inside.current.openInside(room);
  }

  return (
    <>


      <Button type="primary" onClick={showModal}>
        <FontAwesomeIcon style={{ marginRight: '0.75rem' }} icon={faBorderInner} /> Rooms
      </Button>
      <Modal destroyOnClose footer={false} centered width={'auto'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>


        <h2>Theodora™ {props.car.title}</h2>
        <h3>{props.car.body}</h3>

        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={props.car.rooms} />

        <div className="rooms">

        {props.car.rooms.map(function (room) {
              return (
                <div class="room">

                  <div>
                    <img src={'/assets/cars/' + props.car.key + '/rooms/' + room.key + '.png'} />

                    <Tooltip title={room.label}>
                    <Button shape="circle" type="primary" onClick={() => openInside(room.key)}>
                    
                    <FontAwesomeIcon  icon={faPlus} />
                    
                    </Button>
                    </Tooltip>
                  </div>

                </div>
              )
            })}


        </div>

        


      </Modal>


    </>
  );
};

export default Details;
