import React, { useState, useEffect } from 'react';

import { Button, Flex } from 'antd';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrowser } from '@fortawesome/pro-light-svg-icons'


// You need to import the CSS only once
import './assets/css/index.css';

import Logo from './assets/images/logo.svg'

const Header = (props) => {
  return (
    <header id="header-top">
        <div class="logo">
            <img src={Logo} />
        </div>
        <div class="call-to-action">
            <Button href={'https://www.gehealthcare.co.uk/products/mammography'} target='_blank' type="primary">
              <FontAwesomeIcon style={{ marginRight: '0.75rem' }} icon={faBrowser} /> GE Healthcare Website
            </Button>
        </div>
    </header>
  );
};

export default Header;
