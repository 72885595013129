import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadSideGoggles, fa360Degrees } from '@fortawesome/pro-light-svg-icons'

import { Button, Modal } from 'antd';

// You need to import the CSS only once
import './assets/css/index.css';

const Inside = forwardRef((props, ref) => {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nodeId, setNodeId] = useState('node0');

  
  const showModal = () => {
    setNodeId('');
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useImperativeHandle(ref, () => {
    return {
      openInside(context) {
        setIsModalOpen(true);
        setNodeId(context);
      },
    };
  });

  return (
    <>


      <Button type="primary" onClick={showModal}>
        <FontAwesomeIcon style={{ marginRight: '0.75rem' }} icon={fa360Degrees} /> Tour
      </Button>
      <Modal destroyOnClose className={'modal-inside'} style={{ top: 10 }} footer={false} width={'100%'} title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <iframe
        border={0}
        width="100%"
        style={{
          height: '94vh',
          border: '0',
          margin: 0,
          marginBottom: '-0.75rem'
        }}
        src={'/assets/cars/' + props.car.key + '/index.html#'+nodeId}>
      </iframe>

        

      </Modal>


    </>
  );
});
export default Inside;
