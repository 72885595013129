import React, { useState, useEffect, useRef } from 'react';


import { Button, Modal } from 'antd';


import Details from './components/Details';
import Inside from './components/Inside';

// You need to import the CSS only once
import './assets/css/index.css';

const Car = (props) => {

  const detailsRef = useRef();
  const insideRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };




  return (
    <>


      <div class="car-item" onClick={showModal}>

        <div class="image">
          <img src={'/assets/cars/' + props.car.key + '/thumb.png'} />
        </div>

        <div class="title">
          <h2>Theodora™ {props.car.title}</h2>
        </div>

     
        <div class="body">
          <h3>{props.car.body}</h3>
        </div>
     


        <div class="call-to-action">


          <Inside ref={insideRef} car={props.car} />

          <Details ref={detailsRef} inside={insideRef} car={props.car} />


        </div>

      </div>


    </>
  );
};

export default Car;
